.GenreLayout__genre {
  position: absolute;
  overflow: hidden;
  white-space: pre-wrap;
  color: yellow;
  font-weight: 900;
  cursor: pointer;
  transition-property: transform;
  transition-duration: 0.5s;
  z-index: 1;
}

.GenreLayout__genre--displayed {
  transform: scale(1);
  z-index: 2;
}

.GenreLayout__genre--displayed:hover {
  transform: scale(1.2);
  color:  #ffcc00;
  z-index: 3;
}

.GenreLayout__genre--hidden {
  transform: scale(0.2);
}

.GenreLayout__genre--selected {
  transform: scale(2);
  color: cyan;
  z-index: 4;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}
