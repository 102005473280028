.GenreExplorer {
  height: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

@media screen and (max-width: 600px) {
  .GenreExplorer {
    flex-direction: column;
  }
}

.GenreExplorer__sidebar {
  display: flex;
  flex: 0 0 20rem;
  flex-basis: auto;
  width: 20rem;
  background-color: #efefef;
  height: 100%;
}

@media screen and (max-width: 600px) {
  .GenreExplorer__sidebar {
    flex-direction: column;
    flex-basis: content;
    width: 100%;
  }
}
