.GenreMap__zoomcontrols {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 10;
  display: flex;
}

.GenreMap__zoomcontrol {
  width: 3.5rem;
  height: 3.5rem;
  padding: 2px;
  border: 1px solid black;
  text-align: center;
  cursor: pointer;
  background-color: silver
}