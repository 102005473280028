.Intro {
  padding-top: 100px;
  background-color: darkcyan;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.Intro__logo {
  width: 90%;
  align-self: center;
}

.Intro__instructions {
  padding: 10px;
  text-align: center;
  color: #fff;
  font-size: 1.3rem;
}

.Intro__loader {
  height: 5rem;
}

@media screen and (max-width: 600px) {
  .Intro {
    padding-top: 10px;
  }
}