.Jukebox {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  color: #fff;
  opacity: 0.92
}

.Jukebox-container {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
}

.Jukebox__genre {
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  padding: 10px;
}

@media screen and (max-width: 600px) {
  .Jukebox__genre {
    display: none;
  }
}

.Jukebox__random-artist {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  height: 2.5rem;
}

Jukebox__random-artist:disabled,
.Jukebox__random-artist[disabled] {
  opacity: 0.6;
}

.Jukebox__thumbnail {
  position: absolute;
  left: 10000px;
}

.Jukebox__canvas {
  position: absolute;
  left: 10000px;
}