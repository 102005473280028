.GenreMap__container {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.GenreMap__viewport {
  overflow: hidden;
  height: 100%;
  touch-action: none;
}

.GenreMap {
  position: relative;
  opacity: 100%;
  background-size: 100% 100%;
}
